import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { Roles } from '../../models/Role';
import User from '../../models/User';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientService from '../../services/ClientService';
import { Option } from '../Option';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import PermissionUserItem from '../permissions/PermissionUserItem';
import PermissionSingleUser from '../permissions/PermissionSingleUser';
import { SelectListMenu } from '../shared/SelectListMenu';
import UserInvitation from '../../models/UserInvitation';
import InviteOrSearchModal from './InviteOrSearchModal';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { SearchInput } from '../shared/form-control/SearchInput';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import usePermissions from '../../hooks/permissions/usePermissions';

const assignableRoles = [
  Roles.TeamLead,
  Roles.TeamMember,
  Roles.Management,
  Roles.Employee,
  Roles.ExternalContributor,
  Roles.ExternalAuditor,
  Roles.Consultant,
  Roles.ExternalConsultant,
  Roles.ConsultantManager,
];

const UserPermissions: FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [platformRoles, setPlatformRoles] = useState<Option<string, string>[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const hasPermission = usePermissions();

  const { t } = useTranslation(['organisation', 'common']);
  const toasts = useToasts();
  const client = useRecoilValue(currentClientAtom);

  const fetchUsers = useCallback(() => {
    let promise: Promise<User[]>;
    if (!client?.id) {
      return;
    } else {
      promise = ClientService.getUsers(true).then((res) => {
        setUsers(res);
        return res;
      });
    }

    promise.then((users) => {
      const editingUser = users.find((x) => x.id == user?.id);
      if (editingUser) {
        setUser(editingUser);
      }
    });
  }, [client?.id, user?.id]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    setPlatformRoles(assignableRoles.map((role) => ({ id: role, text: t(`common:roles.${role}`), value: role })));
  }, [client?.id, t]);

  const statusOptions = useMemo<Option<string, string>[]>(() => {
    return [
      {
        id: 'enabled',
        text: t('common:user-status.enabled'),
        value: 'enabled',
      },
      {
        id: 'pending',
        text: t('common:user-status.pending'),
        value: 'pending',
      },
      {
        id: 'disabled',
        text: t('common:user-status.deactivated'),
        value: 'disabled',
      },
      {
        id: 'clear',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: null as any,
        text: t('common:list.filter.clear'),
      },
    ];
  }, [t]);

  const allowedPermissions = useMemo(() => {
    return platformRoles.filter((x) => hasPermission(x.value));
  }, [hasPermission, platformRoles]);

  const filteredUsers = useMemo(() => {
    let statusUsers = users;
    if (statusFilter === 'disabled') {
      statusUsers = statusUsers.filter((x) => x.active === false);
    } else if (statusFilter === 'pending') {
      statusUsers = statusUsers.filter((x) => x.active === true && x.verified === false);
    } else if (statusFilter === 'enabled') {
      statusUsers = statusUsers.filter((x) => x.active === true && x.verified === true);
    }

    const query = searchQuery.toLowerCase();

    if (!query) {
      return statusUsers;
    }

    return statusUsers.filter((x) => {
      const firstName = (x?.firstName || '').toLowerCase();
      const lastName = (x?.lastName || '').toLowerCase();
      return firstName.includes(query) || lastName.includes(query);
    });
  }, [searchQuery, statusFilter, users]);

  const inviteUser = (userToInvite: UserInvitation) => {
    if (!client?.id) {
      toasts.addToast({
        title: t('organisation:permissions.toasts.invite-failed'),
        type: ToastType.ERROR,
      });
    } else {
      ClientService.inviteUser(client?.id, userToInvite)
        .then((res) => {
          if (res.data.isNewUser) {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invited'),
              description: t('organisation:permissions.toasts.invited-desc', {
                email: userToInvite?.emailAddress,
                client: client?.name,
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });
          } else {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invite-failed'),
              description: t('organisation:permissions.toasts.invite-failed-desc'),
              type: ToastType.INFO,
              expiresInMs: 10000,
            });
          }
          fetchUsers();
          setInviteUserModalOpen(false);
        })
        .catch((err) => {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invite-failed'),
            description: err?.data?.meta?.message,
            type: ToastType.ERROR,
          });
        });
    }
  };

  const removeUser = (user: User) => {
    if (client?.id && user.id) {
      ClientService.removeUser(client?.id, user.id).then((res) => {
        if (res.data) {
          setUsers((prev) => {
            return prev.filter((x) => x.id !== user.id);
          });
        }
      });
    }
  };

  const onInviteClose = useCallback(() => {
    setInviteUserModalOpen(false);
  }, []);

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    return assignableRoles.indexOf(role.value as Roles) > -1;
  }, []);

  if (user) {
    return <PermissionSingleUser permissions={allowedPermissions} user={user} onBack={() => setUser(null)} fetchUsers={fetchUsers} />;
  }

  return (
    <div className="flex h-full flex-col pt-6">
      <div className="flex items-center justify-between">
        <Heading size={HeadingSize.H3}>{t('organisation:tabs.user-permissions')}</Heading>
        <div className="flex gap-4">
          <div className="w-80">
            <SearchInput
              data-cy="user-search"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              placeholder={t('common:permissions.user-filter-placeholder')}
            />
          </div>
          <Button
            data-cy="show-invite-modal"
            disabled={!hasPermission(Roles.TeamLead)}
            type={ButtonType.PRIMARY}
            size={ButtonSize.M}
            onClick={() => setInviteUserModalOpen(true)}
          >
            {t('common:permissions.buttons.invite')}
          </Button>
        </div>
      </div>

      <div className="-mx-6 flex flex-1 flex-col p-8">
        <div className="flex items-end border-l-2 border-transparent">
          <div className="w-2/6">{t('common:permissions.table-headings.firstname')}</div>
          <div className="w-2/6">{t('common:permissions.table-headings.lastname')}</div>
          <div className="w-2/6">{t('common:permissions.table-headings.email')}</div>
          <div className="flex w-1/6 justify-start">
            <SelectListMenu
              onBlur={() => setFilterOpen(false)}
              isOpen={filterOpen}
              options={statusOptions}
              onClick={(o) => {
                setStatusFilter(o.value as string);
                setFilterOpen(false);
              }}
            >
              {(triggerProps) => (
                <div {...triggerProps}>
                  {statusFilter && <div className="text-dpm-12">{t('common:permissions.table-headings.status')}</div>}
                  <span className="cursor-pointer underline" onClick={() => setFilterOpen(true)}>
                    {statusFilter ? statusOptions.find((x) => x.id === statusFilter)?.text : t('common:permissions.table-headings.status')}
                    <ChevronIcon type={ChevronType.DOWN} className="h-4 w-4" />
                  </span>
                  <div className="relative w-20"></div>
                </div>
              )}
            </SelectListMenu>
          </div>
          <div className="w-10">{/* SPACER */}</div>
          <div className="w-10">{/* SPACER */}</div>
        </div>

        {filteredUsers.length ? (
          filteredUsers.map((user, i) => (
            <PermissionUserItem key={`${user.id}.${i}`} user={user} onUserClick={(user: User) => setUser(user)} onUserRemove={removeUser} />
          ))
        ) : (
          <div data-cy="user-empty" className="flex h-full justify-center ">
            <div className="mt-36 text-center">
              <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
              <Heading size={HeadingSize.H3} className="my-4">
                {t('common:permissions.no-users-found')}
              </Heading>
            </div>
          </div>
        )}
      </div>

      <InviteOrSearchModal
        open={inviteUserModalOpen}
        onClose={onInviteClose}
        onInviteNew={inviteUser}
        filterRoles={inviteRoleFilter}
        clientId={client?.id}
      />
    </div>
  );
};

export default UserPermissions;
